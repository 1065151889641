@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  width: 100%;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.passwordFieldWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.visibilityIcon {
  position: absolute;
  bottom: 9%;
  right: 10px;
  cursor: pointer;
  z-index: 5;
}

.rememberMeCheckbox {
  margin-top: 20px;
}
